<template>
  <el-dialog
    class="department-update-dialog"
    :title="title"
    :visible="true"
    :before-close="
      (() => {
        this.closeModal();
      }).bind(this)
    "
    width="800px"
    :close-on-click-modal="false"
    :modal="true"
  >
    <el-form ref="form" label-width="110px" :model="form" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="产品分类:" required>
            <el-input v-model.trim="form.category_name" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="关联文章" prop="article_id">
            <el-select
              v-model="form.article_id"
              placeholder="请选择"
              style="width: 100%"
              :disabled="isDisabled"
            >
              <el-option
                v-for="item in articleList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="产品名称:" prop="name">
            <el-input
              v-model="form.name"
              :disabled="isDisabled"
              placeholder="请输入产品名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序">
            <el-input-number
              v-model="form.sort"
              :disabled="isDisabled"
              :min="1"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="内容:" prop="content">
            <el-input
              type="textarea"
              :rows="4"
              :disabled="isDisabled"
              placeholder="请输入内容"
              v-model="form.content"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="热卖:" required>
            <el-switch
              v-model="form.is_hot"
              :disabled="isDisabled"
              :active-value="1"
              :inactive-value="-1"
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态:" required>
            <el-switch
              v-model="form.status"
              :disabled="isDisabled"
              :active-value="1"
              :inactive-value="-1"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="封面图:" required>
            <input
              type="file"
              hidden
              ref="upload1"
              @change="uploadingClick($event, 1)"
              accept="image/*"
            />

            <div class="demo-image__preview" v-if="form.cover">
              <div class="remove">
                <i class="el-icon-circle-close" @click="removeClick(1)"></i>
              </div>
              <el-image
                style="width: 100px; height: 100px"
                :src="form.cover"
                :preview-src-list="[form.cover]"
              >
              </el-image>
            </div>
            <el-button
              type="primary"
              size="mini"
              @click="$refs.upload1.click()"
              v-if="!form.cover && !isDisabled"
              >上传封面图</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="主图:" required>
            <input
              type="file"
              hidden
              ref="upload2"
              @change="uploadingClick($event, 2)"
              accept="image/*"
            />
            <div class="demo-image__preview" v-if="form.main">
              <div class="remove">
                <i class="el-icon-circle-close" @click="removeClick(2)"></i>
              </div>
              <el-image
                style="width: 100px; height: 100px"
                :src="form.main"
                :preview-src-list="[form.main]"
              >
              </el-image>
            </div>
            <el-button
              type="primary"
              size="mini"
              @click="$refs.upload2.click()"
              v-if="!form.main && !isDisabled"
              >上传主图</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="相关图片:" required>
            <input
              type="file"
              hidden
              ref="upload3"
              @change="uploadingClick($event, 3)"
              accept="image/*"
            />

            <div style="overflow: hidden">
              <div
                class="demo-image__preview"
                v-for="(item, i) in form.image"
                :key="i"
              >
                <div class="remove">
                  <i
                    class="el-icon-circle-close"
                    @click="removeClick(3, i)"
                  ></i>
                </div>
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="[form.image]"
                >
                </el-image>
              </div>
            </div>
            <div>
              <el-button
                type="primary"
                size="mini"
                @click="$refs.upload3.click()"
                v-if="form.image.length <= 10 && !isDisabled"
                >上传详细图</el-button
              >
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="规格:" required>
            <el-button
              type="primary"
              size="mini"
              @click="addClick"
              style="margin-bottom: 10px"
              v-if="!isDisabled"
              >添加规格</el-button
            >
            <el-table :data="specificationsDate" style="width: 100%" border>
              <el-table-column prop="name" label="名称"> </el-table-column>
              <el-table-column prop="price" label="价格"> </el-table-column>
              <el-table-column prop="stock" label="库存"> </el-table-column>
              <el-table-column prop="sort" label="排序"> </el-table-column>
              <el-table-column prop="status" label="状态">
                <template slot-scope="{ row }">
                  <span v-if="row.status == 1">启用</span>
                  <span v-if="row.status == -1">禁用</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100" v-if="!isDisabled">
                <template slot-scope="{ row, $index }">
                  <el-link type="primary" @click="editClick(row)">编辑</el-link>
                  <el-link
                    type="danger"
                    style="margin-left: 10px"
                    @click="deleteClick($index)"
                    >删除</el-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="closeModal()">取消</el-button>
      <el-button
        size="small"
        type="primary"
        v-if="!isDisabled"
        @click="onConfirm()"
        :loading="loading"
        >确认</el-button
      >
    </div>
    <el-dialog
      append-to-body
      :title="title1"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
      v-if="dialogVisible"
    >
      <div>
        <el-form
          ref="specifications"
          label-width="80px"
          :model="specifications"
          :rules="rules1"
        >
          <el-form-item label="名称：" prop="name">
            <el-input
              v-model.trim="specifications.name"
              placeholder="请输入规格名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="价格：" prop="price">
            <el-input
              v-model.trim="specifications.price"
              placeholder="请输入价格"
            ></el-input>
          </el-form-item>
          <el-form-item label="库存：" prop="stock">
            <el-input
              v-model.trim="specifications.stock"
              placeholder="请输入库存"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序：" prop="sort" required>
            <el-input-number
              v-model="specifications.sort"
              :min="1"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="状态：" prop="status" required>
            <el-switch
              v-model="specifications.status"
              :active-value="1"
              :inactive-value="-1"
            >
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // type  add 添加 addsub 添加子级 edit 修改
    type: {
      type: String,
      default: "add",
    },
    row: {
      type: Object,
    },
    dataTree: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        category_id: "",
        category_name: "",
        article_id: "",
        name: "",
        content: "",
        sort: 1,
        is_hot: -1,
        status: -1,
        cover: "", //封面图
        main: "", //主图
        image: [], // 相关图片
      },
      articleList: [],
      title: "",
      rules: {
        parent_id: [
          {
            required: true,
            message: "请选择父级分类",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入产品分类名称",
            trigger: "blur",
          },
        ],
        article_id: [
          {
            required: true,
            message: "请选择关联文章",
            trigger: "change",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入内容",
          },
        ],
      },
      loading: false,
      dialogVisible: false,
      title1: "新增",
      specifications: {
        name: "",
        price: "",
        stock: "",
        sort: 1,
        status: -1,
      },
      rules1: {
        name: [
          {
            required: true,
            message: "请输入名称",
          },
        ],
        price: [
          {
            required: true,
            message: "请输入价格",
          },
        ],
        stock: [
          {
            required: true,
            message: "请输入库存",
          },
        ],
      },
      specificationsDate: [],
      isDisabled: false,
    };
  },
  computed: {},
  mounted() {
    this.form.category_name = this.row.name;
    if (this.type == "add3") {
      this.title = "添加产品";
      this.isDisabled = false;
      this.form.category_id = this.row.id;
    } else if (this.type == "edit2") {
      this.title = "编辑产品";
      this.getDetails();
      this.isDisabled = false;
      this.form.category_id = this.row.category_id;
    } else if (this.type == "details") {
      this.title = "产品详情";
      this.getDetails();
      this.isDisabled = true;
      this.form.category_id = this.row.category_id;
    }
    this.getArticle();
  },
  methods: {
    addClick() {
      this.title1 = "新增规格";
      this.dialogVisible = true;
      // this.specifications.name = "";
      // this.specifications.price = "";
      // this.specifications.stock = "";
      // this.specifications.sort = 1;
      // this.specifications.status = -1;
    },
    editClick(row) {
      this.title1 = "编辑规格";
      this.dialogVisible = true;
      this.specifications.name = row.name;
      this.specifications.price = row.price;
      this.specifications.stock = row.stock;
      this.specifications.sort = row.sort;
      this.specifications.status = row.status;
      this.specifications.id = row.id;
    },
    deleteClick(i) {
      this.specificationsDate.splice(i, 1);
    },
    submit() {
      if (this.title1 == "新增规格") {
        // if (!this.form.name) {
        //   return this.$message.warning("请输入名称");
        // } else if (!this.form.price) {
        //   return this.$message.warning("请输入价格");
        // } else if (!this.form.stock) {
        //   return this.$message.warning("请输入库存");
        // }

        this.$refs.specifications.validate((val) => {
          if (val) {
            this.specifications["id"] = new Date().getTime();
            let data = JSON.parse(JSON.stringify(this.specifications));
            this.specificationsDate.push(data);
            this.handleClose();
          }
        });
      } else if (this.title1 == "编辑规格") {
        this.$refs.specifications.validate((val) => {
          if (val) {
            let list = JSON.parse(JSON.stringify(this.specificationsDate));
            let form = JSON.parse(JSON.stringify(this.specifications));
            list.forEach((item, i) => {
              if (item.id == form.id) {
                this.specificationsDate.splice(i, 1, form);
              }
            });
            this.handleClose();
          }
        });
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.specifications.name = "";
      this.specifications.price = "";
      this.specifications.stock = "";
      this.specifications.sort = 1;
      this.specifications.status = -1;
    },
    removeClick(type, i) {
      if (type == 1) {
        this.form.cover = "";
      } else if (type == 2) {
        this.form.main = "";
      } else if (type == 3) {
        this.form.image.splice(i, 1);
      }
    },
    uploadingClick(e, type) {
      let files = e.target.files;
      if (files.length == 0) return;
      if (type == 1) {
        this.$util.getUploadSign(1, files[0]).then((url) => {
          this.form.cover = url;
          this.$forceUpdate();
          this.$refs.upload1.value = "";
        });
      } else if (type == 2) {
        this.$util.getUploadSign(1, files[0]).then((url) => {
          this.form.main = url;
          this.$forceUpdate();
          this.$refs.upload2.value = "";
        });
      } else if (type == 3) {
        this.$util.getUploadSign(1, files[0]).then((url) => {
          this.form.image.push(url);
          this.$forceUpdate();
          this.$refs.upload3.value = "";
        });
      }
    },
    // 获取文章列表
    getArticle() {
      this.$axios.post(`/index/article/list`).then((res) => {
        if (res.code == "1") {
          this.articleList = res.data.list;
        }
      });
    },
    getDetails() {
      this.$axios
        .post(`/crm/system/product/detail`, {
          id: this.row.id,
        })
        .then((res) => {
          if (res.code == "1") {
            this.form.name = res.data.name;
            this.form.article_id = res.data.article_id;
            this.form.content = res.data.content;
            this.form.sort = res.data.sort;
            this.form.status = res.data.status;
            this.form.is_hot = res.data.is_hot;
            this.form.cover = res.data.cover;
            this.form.main = res.data.main;
            this.form.image = res.data.image;
            this.specificationsDate = res.data.specifications;
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        });
    },

    closeModal(isrefresh) {
      this.$emit("close", isrefresh);
    },

    onConfirm() {
      this.$refs.form.validate((val) => {
        if (val) {
          if (!this.form.cover) {
            return this.$message.warning("请上传封面图");
          } else if (!this.form.main) {
            return this.$message.warning("请上传主图");
          } else if (this.form.image.length <= 0) {
            return this.$message.warning("请上传详细图");
          } else if (this.specificationsDate.length <= 0) {
            return this.$message.warning("请添加规格");
          }

          this.loading = true;
          let data = {
            name: this.form.name,
            article_id: this.form.article_id,
            category_id: this.form.category_id,
            content: this.form.content,
            cover: this.form.cover,
            main: this.form.main,
            image: this.form.image,
            is_hot: this.form.is_hot,
            sort: this.form.sort,
            status: this.form.status,
            specifications: this.specificationsDate,
          };
          if (this.type == "edit2") data.id = this.row.id;
          this.$axios.post(`/crm/system/product/save`, data).then((res) => {
            if (res.code == "1") {
              this.$message.success(res.msg);
              // this.closeModal(true); // 关闭并刷新
              this.$emit("success", this.form.category_id);
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.demo-image__preview {
  width: 110px;
  height: 110px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
  .remove {
    font-size: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
    cursor: pointer;
    display: none;
  }
  .remove:hover {
    color: #004bc3;
  }
}
.demo-image__preview:hover {
  .remove {
    display: block;
  }
}
</style>

