<template>
  <el-dialog
    :title="title"
    width="400px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :before-close="closeModal"
  >
    <div>
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="员工姓名：" prop="true_name">
          <el-input
            v-model="form.true_name"
            style="width: 100%"
            placeholder="请输入员工姓名"
          ></el-input>
        </el-form-item>

        <el-form-item label="邮箱：" prop="email">
          <el-input
            v-model="form.email"
            placeholder="请输入邮箱"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="设置密码："
          :prop="type == 'edit' ? '' : 'password'"
        >
          <el-input
            show-password
            v-model="form.password"
            :placeholder="type == 'edit' ? '不填默认上次密码' : '请设置密码'"
            auto-complete="new-password"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="确认密码："
          :prop="type == 'edit' ? '' : 'password1'"
        >
          <el-input
            show-password
            v-model="form.password1"
            :placeholder="
              type == 'edit' ? '不填默认上次密码' : '请再次输入密码'
            "
            auto-complete="new-password"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号状态：" prop="status">
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="-1"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">取消</el-button>
      <el-button type="primary" @click="onConfirm" :loading="btnLoading"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "add",
    },
    row: {
      type: Object,
    },
  },

  data() {
    var validatePass1 = (rule, value, callback) => {
      if (this.form.password1 !== this.form.password) {
        callback(new Error("两次输入密码不一致"));
      }
      callback();
    };
    return {
      title: "",
      form: {
        true_name: "",
        password: "",
        password1: "",
        status: "1",
        email: "",
      },
      dialogVisible: true,
      departmentTree: [],
      btnLoading: false,
      rules: {
        true_name: [
          {
            required: true,
            message: "请输入员工姓名",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        department_id: [
          {
            required: true,
            message: "请选择所属部门",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        password1: [
          {
            required: true,
            message: "请再次确认密码",
            trigger: "blur",
          },
          { validator: validatePass1, trigger: "blur" },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  async created() {
    if (this.type == "edit") {
      this.title = "编辑";
      this.form.true_name = this.row.true_name;
      this.form.email = this.row.email;
      this.form.status = this.row.status;
    } else {
      this.title = "新增";
    }
  },
  mounted() {},

  methods: {
    // 提交
    onConfirm() {
      this.$refs.form.validate((val) => {
        if (val) {
          this.btnLoading = true;
          let data = {
            true_name: this.form.true_name,
            email: this.form.email,
            password: this.form.password,
            status: this.form.status,
            roles_id: 1,
          };
          if (this.type == "edit") data.id = this.row.id;
          this.$axios.post(`/crm/system/staff/save`, data).then((res) => {
            if (res.code == "1") {
              this.$message.success(res.msg);
              this.$emit("success", true); // 关闭并刷新
              this.form.true_name = "";
              this.form.email = "";
              this.form.password = "";
              this.form.password1 = "";
              this.form.status = 1;
            } else {
              this.$message.error(res.msg);
            }
            this.btnLoading = false;
          });
        }
      });
    },
    // 取消
    closeModal() {
      this.form.true_name = "";
      this.form.email = "";
      this.form.password = "";
      this.form.password1 = "";
      this.form.status = 1;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.photo-group {
  width: 100%;
  overflow: hidden;
  .photo-item {
    flex-shrink: 0;
    width: 120px;
    height: 100px;
    background: #fff;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #dcdfe6;
    float: left;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 5px;
      background: #fff;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: none;
    }
    &:hover {
      .close {
        display: flex;
      }
    }
  }
}
</style>
