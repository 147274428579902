<template>
  <!-- 企业信息页面 -->
  <div class="corporateInformation">
    <div class="con" v-loading="loading" element-loading-text="拼命加载中...">
      <div class="left">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="120px"
          label-position="left"
        >
          <el-form-item label="企业名称：" prop="name">
            <el-input
              v-model="form.name"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业邮箱：" prop="email">
            <el-input
              v-model="form.email"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请输入企业邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式：" prop="mobile">
            <el-input
              v-model="form.mobile"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请输入联系方式："
            ></el-input>
          </el-form-item>
          <el-form-item label="详细地址：" prop="addrress">
            <el-input
              v-model="form.addrress"
              :readonly="isEdit"
              type="textarea"
              :rows="3"
              style="width: 100%"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>

          <el-form-item label="工作时间：" prop="working_hours">
            <el-input
              v-model="form.working_hours"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请选择工作时间"
            ></el-input>
          </el-form-item>
          <el-form-item label="X 链接：" prop="x">
            <el-input
              v-model="form.x"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请输入X 链接"
            ></el-input> </el-form-item
          ><el-form-item label="Facebook链接：" prop="facebook">
            <el-input
              v-model="form.facebook"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请输入Facebook链接"
            ></el-input> </el-form-item
          ><el-form-item label="Tiktok链接：" prop="tiktok">
            <el-input
              v-model="form.tiktok"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请输入Tiktok链接"
            ></el-input>
          </el-form-item>
          <el-form-item label="YouTube链接：" prop="youtube">
            <el-input
              v-model="form.youtube"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="YouTube链接"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业签名：" prop="sign">
            <el-input
              v-model="form.sign"
              :readonly="isEdit"
              type="textarea"
              :rows="3"
              style="width: 100%"
              placeholder="请输入企业签名"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业简介：" prop="intro">
            <el-input
              v-model="form.intro"
              :readonly="isEdit"
              style="width: 100%"
              type="textarea"
              :rows="3"
              placeholder="请输入企业简介"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="选择理由：" prop="trust_content">
              <el-input
                v-model="form.trust_content"
                style="width: 100%"
                placeholder="请选择理由"
              ></el-input>
            </el-form-item> -->
          <el-form-item label="纬度：" prop="latitude">
            <el-input
              v-model="form.latitude"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请输入纬度"
            ></el-input> </el-form-item
          ><el-form-item label="经度：" prop="longitude">
            <el-input
              v-model="form.longitude"
              :readonly="isEdit"
              style="width: 100%"
              placeholder="请输入经度"
            ></el-input>
          </el-form-item>

          <el-form-item style="margin-top: 20px">
            <el-button type="primary" @click="submit" v-if="!isEdit">{{
              lang_data == 1 ? "确定" : "OK"
            }}</el-button>
            <el-button type="primary" v-if="isEdit" @click="isEdit = false">{{
              lang_data == 1 ? "修改" : "OK"
            }}</el-button>
            <el-button @click="isEdit = true" v-if="!isEdit">{{
              lang_data == 1 ? "取消" : "OK"
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <div class="title">企业头像</div>
        <div
          class="mask-out logo"
          @mouseover="mask = true"
          @mouseleave="mask = false"
        >
          <img v-if="form.logo" class="img" :src="form.logo" />
          <img v-else class="img" src="@/assets/teeth.png" />
          <input
            type="file"
            hidden
            ref="upload"
            @change="onSelectFile"
            accept="image/*"
          />
          <div
            class="mask"
            v-if="!isEdit"
            v-show="mask"
            @click="$refs.upload.click()"
          >
            <span>{{ lang_data == 1 ? "点击更换" : "Click to replace" }}</span>
            <div class="mask-img"></div>
          </div>
        </div>
        <div class="title">企业形象图</div>
        <input
          type="file"
          hidden
          ref="upload1"
          @change="onSelectFile1"
          accept="image/*"
        />
        <div
          class="uploading"
          @click="$refs.upload1.click()"
          v-if="!form.image"
        >
          <i class="el-icon-upload2"></i>
        </div>
        <div class="banner" v-else>
          <div class="demo-image__preview">
            <div class="remove" v-if="!isEdit">
              <i class="el-icon-circle-close" @click="removeClick"></i>
            </div>
            <el-image
              style="width: 120px; height: 120px"
              :src="form.image"
              :preview-src-list="[form.image]"
            >
            </el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        logo: "",
        image: "",
        name: "",
        addrress: "",
        email: "",
        mobile: "",
        working_hours: "",
        x: "",
        facebook: "",
        youtube: "",
        sign: "",
        intro: "",
        trust_content: "",
        latitude: "",
        longitude: "",
      },
      rules: {},
      mask: false,
      isEdit: true,
      lang_data: localStorage.lang_data || 1,
      loading: false,
    };
  },
  mounted() {
    this.getInit();
  },
  methods: {
    editClick() {
      this.isEdit = false;
    },
    getInit() {
      this.loading = true;
      this.$axios
        .post("/crm/system/company/detail")
        .then((res) => {
          if (res.code == 1) {
            this.form = { ...res.data };
          }
          setTimeout(() => {
            this.loading = false;
          }, 200);
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
        });
    },
    removeClick() {
      this.form.image = "";
    },
    submit() {
      this.$axios
        .post("/crm/system/company/save", {
          ...this.form,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.isEdit = true;
            this.getInit();
          } else {
            this.$message.warning(res.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onSelectFile(e) {
      let files = e.target.files;
      if (files.length == 0) return;
      this.$util.getUploadSign(2, files[0]).then((url) => {
        this.form.logo = url;
        this.$forceUpdate();
        this.$refs.upload.value = "";
      });
    },
    onSelectFile1(e) {
      let files = e.target.files;
      if (files.length == 0) return;
      this.$util.getUploadSign(2, files[0]).then((url) => {
        this.form.image = url;
        this.$refs.upload1.value = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.corporateInformation {
  //   border: 1px solid #dcdfe6;
  //   background: #f7f8fb;

  display: flex;
  overflow: auto;
  .con {
    width: 100%;
    height: 100%;
    display: flex;
    .left {
      width: 40%;
    }

    .mask-out {
      width: 120px;
      height: 120px;
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      .img {
        width: 100%;
        height: 100%;
        border: 1px solid #ddd;
        border-radius: 100%;
        box-sizing: border-box;
      }
      .mask {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        line-height: 120px;
        box-sizing: border-box;
        .uploadimg {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          z-index: 1000;
        }
        span {
          color: #ffffff;
          position: relative;
          z-index: 10;
        }
        .mask-img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 100%;
          background: #000000;
          opacity: 0.5;
          z-index: 1;
        }
      }
    }
    .right {
      width: 50%;
      padding-left: 10%;
      .title {
        font-weight: 600;
        padding-bottom: 20px;
      }
      .logo {
        margin-bottom: 50px;
      }
      .uploading {
        width: 120px;
        height: 120px;
        border-radius: 5px;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          font-size: 50px;
          color: #ddd;
        }
      }
      .uploading:hover {
        background: #000000;
        opacity: 0.1;
        i {
          //   color: #004bc3;
        }
      }
      .banner {
        .demo-image__preview {
          width: 120px;
          height: 120px;
          position: relative;
          border: 1px solid #ddd;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          .remove {
            font-size: 20px;
            position: absolute;
            right: 5px;
            top: 5px;
            z-index: 2;
            cursor: pointer;
            display: none;
          }
          .remove:hover {
            color: #004bc3;
          }
        }
        .demo-image__preview:hover {
          .remove {
            display: block;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.corporateInformation {
  .el-form-item {
    margin-bottom: 8px;
  }
  .demo-image__preview {
    .el-image {
      height: auto !important;
    }
  }
}
</style>