<template>
  <div class="staffManagement">
    <div class="top">
      <el-form inline>
        <el-form-item label="名称：">
          <el-input
            v-model="form.true_name"
            clearable
            placeholder="请输入名称"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查询</el-button>
          <el-button type="primary" @click="addClick">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table
        class="radio-table"
        ref="table"
        :data="dataList"
        stripe
        border
        style="width: 100%"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-table-column prop="true_name" label="员工"> </el-table-column>
        <el-table-column prop="email" label="邮箱"> </el-table-column>
        <el-table-column type="status" label="状态" width="120">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.status"
              :active-value="1"
              :inactive-value="-1"
              @change="statusChange($event, row, 2)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-link type="primary" @click="editClick(scope.row)">编辑</el-link>
            <el-link
              type="danger"
              style="margin-left: 10px"
              @click="deleteClick(scope.row)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="pageChange"
          @size-change="sizeChange"
          :total="total_count"
          :page-size="page_size"
          :current-page.sync="current_page"
        >
        </el-pagination>
      </div>
    </div>
    <EditModal
      v-if="editModalShow"
      :type="editModalType"
      :row="editModalRow"
      @close="onSuccess"
      @success="onSuccess"
    >
    </EditModal>
  </div>
</template>

<script>
import EditModal from "./editModal.vue";
export default {
  components: {
    EditModal,
  },
  data() {
    return {
      dataList: [],
      form: {
        true_name: "",
      },
      current_page: 1,
      page_size: 10,
      total_count: 0,
      editModalShow: false,
      editModalType: "add",
      editModalRow: {},
      loading: false,
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    onSuccess() {
      this.editModalShow = false;
      this.getList();
    },
    addClick() {
      this.editModalShow = true;
      this.editModalType = "add";
    },
    editClick(row) {
      this.editModalShow = true;
      this.editModalRow = row;
      this.editModalType = "edit";
    },
    deleteClick(row) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$axios
            .post(`/crm/system/staff/remove`, {
              id: row.id,
            })
            .then((res) => {
              if (res.code == "1") {
                this.$message.success(res.msg);
                this.getList();
              }
            });
        })
        .catch(() => {});
    },
    pageChange(val) {
      this.current_page = val;
      this.getList();
    },
    getList(current_page) {
      this.loading = true;
      var params = {
        page_size: this.page_size,
        current_page: current_page ? current_page : this.current_page,
        true_name: this.form.true_name,
        email: this.form.email,
      };
      this.$axios
        .post("/crm/system/staff/list", params)
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.dataList = res.data.list;
            this.total_count = res.data.total_count || 0;
            this.current_page = res.data.current_page;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //每页条数
    sizeChange(value) {
      this.page_size = value;
      this.getList();
    },
    statusChange(val, row) {
      this.$axios
        .post("/crm/system/staff/onOff", {
          id: row.id,
          status: val,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.$message.success(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.staffManagement {
  .content {
    .page {
      text-align: right;
      padding: 10px 0;
    }
  }
}
</style>