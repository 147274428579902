<template>
  <el-dialog
    class="department-update-dialog"
    :title="title"
    :visible="true"
    :before-close="
      (() => {
        this.closeModal();
      }).bind(this)
    "
    width="500px"
    :close-on-click-modal="false"
    :modal="true"
  >
    <el-form ref="form" label-width="120px" :model="form" :rules="rules">
      <el-form-item label="父节点:" prop="parent_id">
        <Treeselect
          :value="form.parent_id !== undefined ? [form.parent_id] : null"
          placeholder="请选择父节点"
          :options="departmentTree"
          :normalizer="normalizer"
          @select="onSelectDepartment"
          disabled
          searchable
          clearable
        />
      </el-form-item>
      <el-form-item label="产品分类名称" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入产品分类名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-switch v-model="form.status" :active-value="1" :inactive-value="-1">
        </el-switch>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input-number v-model="form.sort" :min="1"></el-input-number>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="closeModal()">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="onConfirm()"
        :loading="loading"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  props: {
    // type  add 添加 addsub 添加子级 edit 修改
    type: {
      type: String,
      default: "add",
    },
    row: {
      type: Object,
    },
    dataTree: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        parent_id: undefined, // 父级id
        name: "",
        sort: null,
        status: -1,
      },
      title: "",
      rules: {
        parent_id: [
          {
            required: true,
            message: "请选择父级分类",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入产品分类名称",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      lang_data: localStorage.lang_data || 1,
    };
  },
  computed: {
    isEdit() {
      return this.row && this.row.id;
    },
    departmentTree() {
      return [
        {
          id: 0,
          name: "根节点",
        },
        ...this.dataTree,
      ];
    },
  },
  mounted() {
    if (this.type == "add1") {
      // 在某个分类下添加子部门
      this.form.parent_id = 0;
      this.title = "新增";
    } else if (this.type == "add2") {
      // 在某个分类下添加子部门
      this.form.parent_id = this.row.id;
      this.title = "新增";
    } else if (this.type == "edit1") {
      this.title = "编辑";
      this.form = {
        parent_id: this.row.parent_id, // 父级部门id
        name: this.row.name,
        sort: this.row.sort,
        status: this.row.status,
      };
    }
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },

    closeModal(isrefresh) {
      this.$emit("close", isrefresh);
    },
    onSelectDepartment(node) {
      this.form.parent_id = node.id;
    },
    onConfirm() {
      this.$refs.form.validate((val) => {
        if (val) {
          this.loading = true;
          let data = { ...this.form };
          if (this.type == "edit1") data.id = this.row.id;
          this.$axios
            .post(`/crm/system/product_category/save`, data)
            .then((res) => {
              if (res.code == "1") {
                this.$message.success(res.msg);
                // this.closeModal(true); // 关闭并刷新
                this.$emit("success", res.data);
              } else {
                this.$message.error(res.msg);
              }
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

