<template>
  <div class="footer">
    <div class="top">
      <div class="content">
        <div class="left">
          <div class="con">
            <img class="img" src="../assets/logo2.png" alt="" />
            <!-- <img :src="row.logo  " alt="" /> -->
            <!-- <div class="text1">A brief promotional</div> -->
            <!-- <div class="text2">slogan for the company</div> -->
            <div class="text3">
              <div class="li" @click="urlClick(1)">
                <img src="../assets/tiktok.png" alt="" />
              </div>
              <div class="li" @click="urlClick(2)">
                <img src="../assets/facebook.png" alt="" />
              </div>
              <div class="li" @click="urlClick(3)">
                <img src="../assets/youTube.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="text1">
            <div class="li1">Precise transfer</div>
            <div class="li2">Easy operation</div>
            <div class="li2">Digital delivery</div>
            <div class="li2">Simplification</div>
            <div class="li2">To a certain extent</div>
          </div>
          <div class="text2">
            <div class="li1">Contact Us</div>
            <div class="li2">Call：{{ row.mobile }}</div>
            <div class="li2">Email：{{ row.email }}</div>
            <div class="li2">Address：{{ row.addrress }}</div>
            <div class="li2">Working hours： {{ row.working_hours }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="content">© 2024 Merlin Dental Laboratory</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      row: {},
    };
  },
  created() {
    this.getinit();
  },
  methods: {
    getinit() {
      return this.$axios.post("/index/index/header_foot").then((res) => {
        if (res.code == 1) {
          this.row = res.data;
        }
      });
    },
    urlClick(type) {
      let url = "";
      if (type == 1) {
        url = this.row.tiktok;
      } else if (type == 2) {
        url = this.row.facebook;
      } else if (type == 3) {
        url = this.row.youtube;
      }
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #4f4f4f;
  box-sizing: border-box;
  overflow: hidden;
  .top {
    height: 260px;
    width: 100%;
    color: #fcfefe;
    .content {
      width: 1200px;
      height: 260px;
      margin: 0 auto;
      display: flex;
      .left {
        width: 300px;
        height: 100%;
        flex: none;
        display: flex;
        margin-left: 20px;
        .con {
          width: 300px;
          height: 100%;
          .img {
            margin-top: 40px;
          }
          .text1 {
            margin-top: 20px;
            font-size: 13px;
          }
          .text2 {
            margin-top: 10px;
            font-size: 13px;
          }
          .text3 {
            margin-top: 50px;
            display: flex;
            .li {
              margin-right: 10px;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
              img:hover {
                box-shadow: 0 0 10px 2px #333;
              }
            }
          }
        }
      }
      .right {
        width: 900px;
        height: 100%;
        flex: none;
        display: flex;
        font-size: 13px;
        .text1 {
          width: 300px;
          height: 100%;
          flex: none;
        }
        .text2 {
          width: 500px;
          height: 100%;
          flex: none;
        }
        .li1 {
          margin-top: 50px;
          margin-bottom: 30px;
          font-size: 14px;
          font-weight: 600;
        }
        .li2 {
          margin-bottom: 10px;
        }
      }
    }
  }
  .bottom {
    height: 40px;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #fff;
    font-size: 13px;
    .content {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fcfefe;
    }
  }
}
@media (max-width: 750px) {
  .footer {
    height: auto;
    .top {
      height: 450px;
      .content {
        position: relative;
        display: block;
        width: 100%;
        height: auto;

        .left {
          display: block;
          position: absolute;
          left: 20px;
          margin: 0;
          height: auto;
          .con {
            width: 50%;
          }
        }
        .right {
          .text1 {
            width: 160px;
            position: absolute;
            right: 0;
            top: 0;
            height: auto;
            .li1 {
              margin-top: 40px;
            }
          }
          .text2 {
            width: auto;
            position: absolute;
            left: 0;
            padding: 0 20px;
            top: 220px;
            border-top: 1px solid #ddd;
            height: auto;
            .li1 {
              margin-top: 30px;
            }
          }
        }
      }
    }
    .bottom {
      width: 100%;
      .content {
        width: 100%;
        font-size: 12px;
      }
    }
  }
}
</style>