<template>
  <div class="productIntroduction">
    <div class="banner">
      <div class="content">
        <div class="text1">
          Multi-function scanbody sets healing, oral scan, take model and other
          functions into an organic whole
        </div>
        <div class="text2">
          scanning device is suitable for different brands, improve the
          efficiency of clinical operation
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">Product Introduction</div>
      <div class="information">
        <el-collapse v-model="activeNames" accordion @change="handleChange">
          <el-collapse-item
            :title="item.title"
            :name="item.id"
            v-for="item in row.list"
            :key="item.id"
          >
            <div
              class="vHtml"
              v-html="content"
              v-loading="loading"
              @click="showImage($event)"
              element-loading-text="Loading..."
            ></div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    
    <el-image-viewer
      v-if="dialogVisible"
      :on-close="closeImage"
      :url-list="[url]"
      :z-index="9999"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: { ElImageViewer },
  data() {
    return {
      row: {},
      activeNames: [],
      detailsData: {},
      content: "",
      loading: false,
      dialogVisible: false,
      url: "",
    };
  },
  async created() {
    await this.getinit();
  },
  async mounted() {
    let val = this.$route.query.id;
    await this.getDetails(val);
    if (val) {
      this.activeNames.push(Number(val));
    }
  },
  watch: {},
  methods: {
    showImage(e) {
      if (e.target.tagName == "IMG") {
        this.url = e.target.src;
        this.dialogVisible = true;
      }
      document.body.style.overflow = "hidden"
    },
    closeImage() {
      this.dialogVisible = false;
      this.url = "";
      document.body.style.overflow = "auto"
    },

    getinit() {
      return this.$axios.post("/index/article/list", {}).then((res) => {
        if (res.code == 1) {
          this.row = res.data;
        }
      });
    },
    async handleChange(id) {

        // let data = this.row.list.filter((item) => item.id == id);
        await this.getDetails(id);
      
    },
    async getDetails(id) {
      this.loading = true;
      return this.$axios
        .post("/index/article/detail", {
          id: id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.content = decodeURIComponent(res.data.content);
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.productIntroduction {
  width: 100%;
  overflow: hidden;
  .img {
    width: 100%;
    height: 100%;
    position: fixed;
    // z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner {
    width: 100%;
    height: 500px;
    background: url("../assets/banner.png") no-repeat 100% 50%;
    background-size: 100%;
    overflow: hidden;

    .content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      text-align: right;
      overflow: hidden;
      position: relative;
      .text1 {
        font-size: 40px;
        color: #fff;
        word-wrap: break-word;
        position: absolute;
        top: 140px;
        right: 0;
        animation: banner1 2s ease;
      }
      .text2 {
        font-size: 20px;
        position: absolute;
        top: 260px;
        right: 0;
        color: #fff;
        animation: banner2 2s ease;
      }
    }
  }
  .content {
    width: 1200px;
    margin: 50px auto;

    .title {
      font-size: 40px;
      margin-bottom: 30px;
      font-weight: 600;
      padding-left: 20px;
      border-left: 5px solid #004bc3;
      margin-left: 12px;
    }
    .information {
      width: 100%;
      min-height: 500px;
      // height: 600px;
      .vHtml {
        min-height: 200px;
      }
    }
  }
  @keyframes banner1 {
    0% {
      opacity: 0;
      top: 100px;
    }
    100% {
      opacity: 1;
      top: 140px;
    }
  }
  @keyframes banner2 {
    0% {
      opacity: 0;
      top: 220px;
    }
    100% {
      opacity: 1;
      top: 260px;
    }
  }
}
@media (max-width: 750px) {
  .productIntroduction {
    .banner {
      height: 150px;
      background-size: cover;
      .content {
        width: 100%;
        margin: 0;
        .text1 {
          font-size: 14px;
          padding: 10% 5% 0 30%;
          animation: none;
          top: 0;
        }
        .text2 {
          display: none;
          animation: none;
          top: 0;
        }
      }
    }

    .content {
      width: 100%;
      margin: 0px;
      .title {
        font-size: 16px;
        margin: 20px;
      }
      .li {
        width: 90%;
        margin: 0% 5% 5% 5%;
        overflow: hidden;
        .text1 {
          width: 100%;
        }
      }
      .li:hover {
        box-shadow: none;
        transform: translateY(0px);
      }
    }
    .information ::v-deep .el-collapse-item__header {
      font-size: 12px !important;
      height: 40px !important;
      padding: 0 20px;
    }
    .information ::v-deep video {
      width: 100% !important;
    }
    .information ::v-deep img {
      width: auto !important;
      max-width: 100% !important;
      height: auto !important;
    }
    .information {
      .vHtml {
        padding: 0 20px;
      }
    }
  }
}
</style>
<style lang="scss">
.productIntroduction {
  .el-collapse-item__header {
    height: 80px !important;
    font-size: 20px !important;
    font-weight: 600;
  }
}
</style>