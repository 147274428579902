<template>
  <!-- 文章管理页面-->
  <div class="productManagement">
    <div class="left">
      <div class="title">
        <b>文章类型</b>
        <el-button
          type="primary"
          size="mini"
          class="ml10"
          @click="addAttributes()"
          >添加</el-button
        >
      </div>
      <ul>
        <li
          v-for="(item, i) in tabsList"
          :key="item.id"
          @click="onTypeChange(item, i)"
          :class="{ selected: selectedIndex == i }"
          style="display: flex; justify-content: space-between"
        >
          <div>
            <div>{{ item.name }}</div>
            <el-switch
              v-model="item.status"
              :active-value="1"
              :inactive-value="-1"
              @change="statusChange($event, item, 1)"
            >
            </el-switch>
          </div>
          <div>
            <i
              class="el-icon-delete"
              style="color: #f56c6c"
              @click.stop="deleteClick(item, 1)"
            ></i>
            <i
              class="el-icon-circle-plus-outline"
              style="color: #004bc3; margin-right: 10px"
              @click.stop="openGiftModal(item, 1)"
            ></i>
            <i
              class="el-icon-edit"
              style="color: #004bc3; margin-right: 10px"
              @click.stop="editClick(item, 1)"
            ></i>
          </div>
        </li>
      </ul>
    </div>

    <div class="right">
      <div class="table-list" style="margin-top: 0">
        <div class="table-list-wrap">
          <!-- :max-height="clientHeight - 100" -->
          <el-table
            class="radio-table"
            ref="table"
            :data="dataList"
            stripe
            border
            style="width: 100%"
            v-loading="loading"
            element-loading-text="拼命加载中..."
          >
            <el-table-column prop="title" label="文章名称"> </el-table-column>
            <el-table-column prop="sort" label="排序" width="80">
            </el-table-column>
            <el-table-column type="status" label="状态" width="120">
              <template slot-scope="{ row }">
                <el-switch
                  v-model="row.status"
                  :active-value="1"
                  :inactive-value="-1"
                  @change="statusChange($event, row, 2)"
                >
                </el-switch>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-link type="primary" @click="openGiftModal(scope.row, 2)"
                  >编辑</el-link
                >
                <el-link
                  type="danger"
                  style="margin-left: 10px"
                  @click="deleteClick(scope.row, 2)"
                  >删除</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="pageChange"
              @size-change="sizeChange"
              :total="total_count"
              :page-size="page_size"
              :current-page.sync="current_page"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加编辑 -->
    <el-dialog
      v-if="dialogVisible"
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      width="450px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="110px"
        class="demo-form"
      >
        <el-form-item label="类型名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入类型名称"></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="name">
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="-1"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input-number
            v-model="form.sort"
            placeholder="请输入排序"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitForm('form')">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible1"
      class="clinic-Audit-dialog"
      :title="idAddEdit == 1 ? '新增文章' : '编辑文章'"
      :before-close="closeModal"
      :close-on-click-modal="false"
      width="1430px"
      top="100px"
    >
      <el-form :model="form1.info" label-width="100px" inline>
        <el-row>
          <el-col :span="24">
            <el-form-item label="文章标题：" required>
              <el-input
                v-model.trim="form1.name"
                style="width: 400px"
                :rows="5"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="排序：">
              <el-input-number
                v-model="form1.sort"
                :min="0"
                label="排序"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="启用：">
              <el-switch
                v-model="form1.status"
                :active-value="1"
                :inactive-value="-1"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="content" v-if="dialogVisible1">
        <div style="border: 1px solid #ccc">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            mode="default"
          />
          <Editor
            style="height: 455px; overflow-y: hidden"
            v-model="form1.ruleDetail"
            :defaultConfig="editorConfig"
            mode="default"
            @onCreated="onCreated"
          />
        </div>
      </div>

      <div slot="footer" style="text-align: center">
        <el-button size="small" @click="closeModal">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
export default {
  name: "itemTypeManagement",
  components: {
    Editor,
    Toolbar,
  },
  data() {
    return {
      loading: false,
      tabsList: [],
      dataList: [],
      selectedIndex: 0,
      dialogVisible: false,
      dialogVisible1: false,
      rules: {
        name: [{ required: true, message: "请输入类型名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择口型", trigger: "change" }],
      },
      form: {
        name: "",
        sort: 1,
        status: -1,
      },
      form1: {
        name: "",
        sort: "0",
        status: "",
        ruleDetail: "",
      },
      title: "",
      projectName: "请选择",
      current_page: 1,
      page_size: 10,
      total_count: 0,
      category_id: "",
      id1: "",
      id2: "",
      idAddEdit: 1,
      toolbarConfig: {},
      editor: {},
      editorConfig: {
        placeholder: "请输入内容...",
        autoFocus: false, //配置编辑器默认是否 focus
        MENU_CONF: {
          uploadImage: {
            maxFileSize: 10 * 1024 * 1024, // 单个文件的最大体积限制
            maxNumberOfFiles: 1, //最多可上传几个文件
            customUpload: async (file, insertFn) => {
              let resultUrl = await this.$util.getUploadSign(1, file);
              insertFn(resultUrl);
            },
          },
          uploadVideo: {
            maxFileSize: 100 * 1024 * 1024, // 单个文件的最大体积限制
            maxNumberOfFiles: 1, //最多可上传几个文件
            customUpload: async (file, insertFn) => {
              let resultUrl = await this.$util.getUploadSign(1, file);
              insertFn(resultUrl);
            },
          },
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.getTabsList();
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    pageChange(val) {
      this.current_page = val;
      this.onTypeChange(this.tabsList[this.selectedIndex], this.selectedIndex);
    },
    //每页条数
    sizeChange(value) {
      this.page_size = value;
      this.onTypeChange(this.tabsList[this.selectedIndex], this.selectedIndex);
    },
    async openGiftModal(row, index) {
      this.idAddEdit = index;
      if (index == 1) {
        this.category_id = row.id;
        this.id2 = null;
      } else if (index == 2) {
        this.id2 = row.id;
        this.category_id = row.category_id;
        this.getDetails(row.id);
      }
      setTimeout(() => {
        this.dialogVisible1 = true;
      });
    },
    getDetails(id) {
      return this.$axios
        .post("/crm/system/article/detail", { id: id })
        .then((res) => {
          if (res) {
            // this.form1.ruleDetail = decodeURIComponent(res.content);

            this.form1.ruleDetail = res.content;
            this.form1.name = res.title;
            this.form1.sort = res.sort;
            this.form1.status = res.status;
            this.$forceUpdate();
          }
        });
    },
    confirm() {
      let params = {
        id: this.id2 ? this.id2 : null,
        title: this.form1.name,
        content: this.form1.ruleDetail,
        // content: encodeURIComponent(this.form1.ruleDetail),
        sort: this.form1.sort,
        status: this.form1.status,
        category_id: this.category_id,
      };
      this.$axios.post("/crm/system/article/save", params).then((res) => {
        this.loading = false;
        if (res.code != 1) {
          this.$message.error(res.msg);
        } else {
          this.$message.success(res.msg);
          this.tabsList.forEach((item, i) => {
            if (item.id == this.category_id) {
              this.selectedIndex = i;
            }
          });
          this.handleClose();
          this.onTypeChange(
            this.tabsList[this.selectedIndex],
            this.selectedIndex
          );
        }
      });
    },
    closeModal() {
      this.dialogVisible1 = false;
      this.form1.name = "";
      this.form1.sort = "0";
      this.form1.status = -1;
      this.form1.ruleDetail = "";
    },
    statusChange(val, row, type) {
      if (type == 1) {
        this.$axios
          .post("/crm/system/article_category/onOff", {
            id: row.id,
            status: val,
          })
          .then((res) => {
            if (res.code != 1) {
              this.$message.error(res.msg);
            } else {
              this.$message.success(res.msg);
            }
          });
      } else if (type == 2) {
        this.$axios
          .post("/crm/system/article/onOff", {
            id: row.id,
            status: val,
          })
          .then((res) => {
            if (res.code != 1) {
              this.$message.error(res.msg);
            } else {
              this.$message.success(res.msg);
              // this.onTypeChange();
            }
          });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/crm/system/article_category/save", {
              name: this.form.name,
              sort: this.form.sort,
              status: this.form.status,
              id: this.id1 ? this.id1 : null,
            })
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.handleClose();
                this.getTabsList();
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.removeValue();
    },
    removeValue() {
      this.form.name = "";
      this.form.status = -1;
      this.form.sort = 1;
      this.id1 = "";
      this.id2 = "";
      this.form1.name = "";
      this.form1.sort = "0";
      this.form1.status = -1;
      this.form1.ruleDetail = "";
    },
    addAttributes() {
      this.removeValue();
      this.title = "添加文章类型";
      this.dialogVisible = true;
    },
    getTabsList() {
      this.$axios.post("/crm/system/article_category/list").then((res) => {
        if (res.code == 1) {
          this.tabsList = res.data;
          this.category_id = this.tabsList[0].id;
          this.dataList = [];
          this.onTypeChange(
            this.tabsList[this.selectedIndex],
            this.selectedIndex
          );
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 编辑
    editClick(item) {
      this.id1 = item.id;
      this.form.sort = item.sort;
      this.form.status = item.status;
      this.form.name = item.name;
      this.title = "编辑文章类型";
      this.dialogVisible = true;
    },
    // 删除项目类型
    deleteClick(item, type) {
      let val = item.name || item.title;
      this.$confirm("确认要删除【" + val + "】吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == 1) {
            this.$axios
              .post("/crm/system/article_category/remove", {
                id: item.id,
              })
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success(res.msg);
                  this.handleClose();
                  this.getTabsList();
                } else {
                  this.$message.error(res.msg);
                }
              });
          } else {
            this.$axios
              .post("/crm/system/article/remove", {
                id: item.id,
              })
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success(res.msg);
                  this.handleClose();

                  this.onTypeChange(
                    this.tabsList[this.selectedIndex],
                    this.selectedIndex
                  );
                } else {
                  this.$message.error(res.msg);
                }
              });
          }
        })
        .catch(() => {});
    },

    onTypeChange(item, i) {
      this.loading = true;
      this.selectedIndex = i;
      this.category_id = item.id;
      this.$axios
        .post("/crm/system/article/list", {
          category_id: item.id,
          current_page: this.current_page,
          page_size: this.page_size,
        })
        .then((res) => {
          if (res.code == 1) {
            this.dataList = res.data.list;
            this.current_page = res.data.current_page;
            this.total_count = res.data.total_count;
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.radio-table {
  thead .el-table-column--selection .cell {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
.edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    color: #004bc3;
    cursor: pointer;
  }
}
.clinic-Audit-dialog {
  overflow: hidden;
  .content {
    display: flex;
  }
}
::v-deep .ql-editor {
  height: 430px;
}
.productManagement {
  display: flex;
  height: 100%;
  .right {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .page {
      text-align: right;
      padding: 10px 0;
    }
  }
  .left {
    width: 300px;
    border: 1px solid #ddd;
    .title {
      padding: 10px;
      background: #f7f8fb;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    ul {
      li {
        list-style: none;
        padding: 10px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        .el-icon-edit,
        .el-icon-delete {
          float: right;
          margin-top: 2px;
        }
      }
      .selected {
        color: #004bc3;
        font-weight: 600;
        .el-icon-edit,
        .el-icon-delete {
          display: inline-block;
        }
      }
      li:hover {
        .el-icon-edit,
        .el-icon-delete {
          display: inline-block;
        }
      }
    }
  }
}
</style>
