import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: "登录" },
    component: () => import('@/views/admin/login.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { title: "官网设置" },
    component: () => import('@/views/admin/admin.vue'),
    children: [
      {
        path: '/',
        name: 'corporateInformation',
        meta: {
          title: "企业信息"
        },
        component: () => import('@/views/admin/corporateInformation/corporateInformation.vue')
      },
      {
        path: '/articleManagement',
        name: 'articleManagement',
        meta: {
          title: "文章管理"
        },
        component: () => import('@/views/admin/articleManagement/articleManagement.vue')
      },
      {
        path: '/productManagement',
        name: 'productManagement',
        meta: {
          title: "项目管理"
        },
        component: () => import('@/views/admin/productManagement/productManagement.vue')
      },
      {
        path: '/staffManagement',
        name: 'staffManagement',
        meta: {
          title: "员工管理"
        },
        component: () => import('@/views/admin/staffManagement/staffManagement.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/home/home.vue'),
    children: [
      {
        path: '/',
        name: 'homePage',
        meta: {
          title: "首页"
        },
        component: () => import('@/views/homePage.vue')
      },
      {
        path: '/productList',
        name: 'productList',
        meta: {
          title: "产品列表",
        },
        component: () => import('@/views/productList.vue')
      },
      {
        path: '/productIntroduction',
        name: 'productIntroduction',
        meta: {
          title: "产品介绍",
        },
        component: () => import('@/views/productIntroduction.vue')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: {
          title: "关于我们",
        },
        component: () => import('@/views/aboutUs.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  // }

})

export default router