<template>
  <!-- 产品分类管理 页面 -->
  <div class="projectSortManagement">
    <div class="sortContent">
      <div class="left">
        <div class="title">
          <b>产品类型</b
          ><el-button
            type="primary"
            size="mini"
            class="ml10"
            @click.stop="addclick('add1')"
            >添加</el-button
          >
        </div>
        <div class="leftCon">
          <el-tree
            :data="dataList"
            :props="{ children: 'children', label: 'name' }"
            @node-click="handleNodeClick"
            highlight-current
            default-expand-all
            node-key="id"
            ref="tree"
            :expand-on-click-node="false"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>

              <span>
                <span @click.stop="">
                  <el-switch
                    v-model="data.status"
                    :active-value="1"
                    :inactive-value="-1"
                    @change="statusChange(1, data.status, data)"
                    style="margin-right: 10px"
                  >
                  </el-switch>
                </span>
                <i
                  class="el-icon-circle-plus-outline"
                  style="color: #004BC3; margin-right: 10px"
                  v-if="data.type == 1"
                  @click.stop="addclick('add2', data)"
                ></i>
                <i
                  v-if="data.type == 2"
                  class="el-icon-circle-plus-outline"
                  style="color: #004BC3; margin-right: 10px"
                  @click.stop="addclick('add3', data)"
                ></i>
                <i
                  class="el-icon-edit"
                  style="color: #004BC3; margin-right: 10px"
                  @click.stop="addclick('edit1', data)"
                ></i>

                <i
                  class="el-icon-delete"
                  style="color: #f56c6c"
                  @click.stop="deleteClick(data, 1)"
                ></i>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
      <div class="right">
        <el-table :data="tableList" border ref="tableList" style="width: 100%">
          <el-table-column prop="name" label="产品名称"> </el-table-column>

          <el-table-column prop="sort" label="状态" width="100">
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.status"
                :active-value="1"
                :inactive-value="-1"
                @change="statusChange(2, row.status, row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="is_hot_label" label="热卖" width="80">
          </el-table-column>
          <el-table-column prop="sort" label="排序" width="80">
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="updatePosition('edit2', scope.row)"
                style="margin-right: 10px"
                >编辑</el-link
              >
              <el-link
                type="primary"
                @click="updatePosition('details', scope.row)"
                style="margin-right: 10px"
                >详情</el-link
              >
              <el-link type="danger" @click="removePosition(scope.row)"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="pageChange"
            @size-change="sizeChange"
            :total="total_count"
            :page-size="page_size"
            :current-page.sync="current_page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <EditModal
      v-if="editModalShow"
      :type="type"
      :row="editModalRow"
      :dataTree="dataList"
      @close="onClose"
      @success="onSuccess"
    >
    </EditModal>
    <EditModal1
      v-if="editModalShow1"
      :type="type"
      :row="editModalRow"
      :dataTree="dataList"
      @close="onClose"
      @success="onSuccess1"
    >
    </EditModal1>
  </div>
</template>

<script>
import EditModal from "./editModal";
import EditModal1 from "./editModal1";
export default {
  components: { EditModal, EditModal1 },
  async mounted() {
    await this.getList(true);
  },
  data() {
    return {
      dataList: [],
      tableList: [],

      editModalShow: false,
      editModalShow1: false,
      editModalRow: null,
      type: "",
      current_page: 1,
      page_size: 10,
      total_count: 0,
      dataValue: {},
    };
  },
  methods: {
    pageChange(val) {
      this.current_page = val;
      this.getTableList(this.dataValue.id);
    },
    //每页条数
    sizeChange(value) {
      this.page_size = value;
    },
    handleNodeClick(data) {
      this.dataValue = data;
      this.getTableList(data.id);
    },
    getTableList(id) {
      this.$axios
        .post("/crm/system/product/list", {
          category_id: id,
          current_page: this.current_page,
          page_size: this.page_size,
        })
        .then((res) => {
          if (res.code == 1) {
            this.tableList = res.data.list;
            this.current_page = res.data.current_page;
            this.total_count = res.data.total_count;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addclick(type, data) {
      this.type = type;
      this.editModalRow = data;
      if (type == "add1" || type == "add2" || type == "edit1") {
        this.editModalShow = true;
      } else if (type == "add3" || type == "details" || type == "edit2") {
        this.editModalShow1 = true;
      }
    },
    deleteClick(data, type) {
      let url = "";
      if (type == 1) {
        url = "/crm/system/product_category/remove";
      } else if (type == 2) {
        url = "/crm/system/product/remove";
      }
      this.$confirm("确认要删除【" + data.name + "】?", "提示")
        .then(() => {
          let params = {
            id: data.id,
          };
          this.$axios.post(url, params).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);

              if (type == 1) {
                this.getList(true);
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },

    statusChange(type, val, row) {
      let url = "";
      if (type == 1) {
        url = "/crm/system/product_category/onOff";
      } else if (type == 2) {
        url = "/crm/system/product/onOff";
      }
      this.$axios
        .post(url, {
          id: row.id,
          status: val,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.$message.success(res.msg);
          }
        });
    },
    async getList(type) {
      return this.$axios
        .post("/crm/system/product_category/list")
        .then((res) => {
          if (res.code == 1) {
            this.dataList = res.data;
            this.dataList.forEach((item) => {
              this.$set(item, "type", 1);
              if (item.children) {
                item.children.forEach((item1) => {
                  this.$set(item1, "type", 2);
                  if (item1.children) {
                    item1.children.forEach((item2) => {
                      this.$set(item2, "type", 3);
                    });
                  }
                });
              }
            });
            if (type) {
              setTimeout(() => {
                this.$refs.tree.setCurrentKey(this.dataList[0].children[0].id);
                this.getTableList(this.dataList[0].children[0].id);
              });
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updatePosition(type, row) {
      this.type = type;
      this.editModalRow = row;
      if (type == "add1" || type == "add2" || type == "edit1") {
        this.editModalShow = true;
      } else if (type == "add3" || type == "details" || type == "edit2") {
        this.editModalShow1 = true;
      }
    },
    onClose() {
      this.editModalShow = false;
      this.editModalShow1 = false;
    },
    async onSuccess(id) {
      this.editModalShow = false;
      await this.getList();
      setTimeout(() => {
        this.$refs.tree.setCurrentKey(id);
        this.getTableList(id);
      });
    },
    async onSuccess1(id) {
      this.editModalShow1 = false;
      setTimeout(() => {
        this.$refs.tree.setCurrentKey(id);
        this.getTableList(id);
      });
    },

    removePosition(row) {
      this.$confirm("确认要删除【" + row.name + "】?", "提示")
        .then(() => {
          let params = {
            id: row.id,
          };
          this.$axios.post("/crm/system/product/remove", params).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getTableList(this.dataValue.id);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.projectSortManagement {
  display: flex;
  height: 100%;
  flex-direction: column;
  .sortContent {
    display: flex;
    height: 100%;
    .left {
      width: 400px;
      flex: none;
      border: 1px solid #ddd;
      .title {
        padding: 10px;
        background: #f7f8fb;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
      }
    }
    .right {
      width: 100%;
      margin-left: 10px;
      .page {
        text-align: right;
        padding: 10px 0;
      }
    }
  }
}
</style>
<style lang="scss">
.projectSortManagement {
  .el-table--border .el-table__cell:first-child .cell {
    font-weight: 600 !important;
    color: #333 !important;
  }

  .el-icon-arrow-right:before {
    font-weight: 600;
  }
  .el-tree-node__content {
    height: 36px !important;
    border-bottom: 1px solid #ddd;
  }
  .el-tree-node__expand-icon {
    font-size: 18px !important;
    color: #999;
  }

  // 改变颜色后，这个一定要设成透明
  .el-tree-node__expand-icon.is-leaf {
    color: transparent !important;
  }
  .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    color: #004bc3;
    font-weight: 600;
  }
}
</style>
