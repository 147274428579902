<template>
  <div class="homePage">
    <div class="banner">
      <div class="content">
        <div class="text1">
          Multi-function scanbody sets healing, oral scan, take model and other
          functions into an organic whole
        </div>
        <div class="text2">
          scanning device is suitable for different brands, improve the
          efficiency of clinical operation
        </div>
        <div class="text3">
          <el-button class="btn" type="primary" plain round @click="routerClick"
            >Learn more Products</el-button
          >
          <el-button
            class="btn"
            type="primary"
            plain
            round
            @click="onlineShow = true"
            >Online message</el-button
          >
        </div>
      </div>
    </div>

    <!-- <div class="introduce">
      <div class="left">
        <div class="text1">About Us</div>
        <div class="text2">
          A brief introduction copy A brief introduction copy
        </div>
        <div class="text3">
          A paragraph of text A paragraph of textA paragraph of textA paragraph
          of textA paragraph of text A paragraph of textA paragraph of textA
          paragraph of textA paragraph of text A paragraph of textA paragraph of
          textA paragraph of textA paragraph of text A paragraph of textA
          paragraph of textA paragraph of text
        </div>
        <div class="text3">
          A paragraph of text A paragraph of textA paragraph of textA paragraph
          of textA paragraph of text A paragraph of textA paragraph of textA
          paragraph of textA paragraph
        </div>
      </div>
      <div class="right">
        <div class="li">
          <p class="p1">2000+</p>
          <p>A brief introduction copy</p>
        </div>
        <div class="li">
          <p class="p1">2000+</p>
          <p>A brief introduction copy</p>
        </div>
        <div class="li">
          <p class="p1">2000+</p>
          <p>A brief introduction copy</p>
        </div>
        <div class="li">
          <p class="p1">2000+</p>
          <p>A brief introduction copy</p>
        </div>
      </div>
    </div> -->
    <div class="product">
      <div class="content">
        <div class="title">Hot selling products</div>
        <div
          v-for="(item, i) in row.hot_product"
          :key="i"
          class="li"
          @click="liClick(item.id)"
        >
          <div class="text1"><img :src="item.cover" alt="" /></div>
          <div class="text2">
            {{ item.category }}
          </div>
          <div class="text3">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="information">
      <div class="content">
        <div class="text2">Why should you trust us ?</div>
        <div class="text3">
          Your benefit if you choose us to provide healthcare
        </div>
        <div class="list">
          <div class="li">
            <div class="li1">
              <img src="../assets/icon1.png" alt="" />
              <span>A paragraph of text</span>
            </div>
            <div class="li2">
              A paragraph of text A paragraph of textA paragraph of textA
              paragraph of text
            </div>
          </div>
          <div class="li">
            <div class="li1">
              <img src="../assets/icon1.png" alt="" />
              <span>A paragraph of text</span>
            </div>
            <div class="li2">
              A paragraph of text A paragraph of textA paragraph of textA
              paragraph of text
            </div>
          </div>
          <div class="li">
            <div class="li1">
              <img src="../assets/icon1.png" alt="" />
              <span>A paragraph of text</span>
            </div>
            <div class="li2">
              A paragraph of text A paragraph of textA paragraph of textA
              paragraph of text
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <ProductDetails
      v-if="liShow"
      :id="id"
      @close="liShow = false"
    ></ProductDetails>
    <OnlineContact
      v-if="onlineShow"
      @close="onlineShow = false"
    ></OnlineContact>
  </div>
</template>

<script>
import ProductDetails from "../components/productDetails.vue";
import OnlineContact from "../components/onlineContact.vue";
export default {
  components: {
    ProductDetails,
    OnlineContact,
  },
  data() {
    return {
      row: {},
      liShow: false,
      id: "",
      onlineShow: false,
    };
  },
  async created() {
    await this.getinit();
  },
  methods: {
    routerClick() {
      this.$router.push("/productList");
    },
    getinit() {
      return this.$axios
        .post("/", {
          page_size: 100,
        })
        .then((res) => {
          if (res.code == 1) {
            this.row = res.data;
          }
        });
    },
    liClick(id) {
      this.id = id;
      this.liShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.homePage {
  width: 100%;
  .banner {
    width: 100%;
    height: 500px;
    background: url("../assets/banner.png") no-repeat 100% 50%;
    background-size: 100%;
    overflow: hidden;
    .content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      text-align: right;
      overflow: hidden;
      position: relative;
      .text1 {
        font-size: 40px;
        color: #fff;
        word-wrap: break-word;
        position: absolute;
        top: 120px;
        right: 0;
        animation: banner1 2s ease;
      }
      .text2 {
        font-size: 20px;
        position: absolute;
        top: 240px;
        right: 0;
        color: #fff;
        animation: banner2 2s ease;
      }
      .text3 {
        position: absolute;
        top: 300px;
        right: 0;
        animation: banner3 2s ease;
        .btn {
          margin-left: 50px;
        }
      }
    }
  }
  .introduce {
    width: 1200px;
    height: 500px;
    display: flex;
    margin: 0 auto;
    .left {
      width: 800px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .text1 {
        font-size: 24px;
        font-weight: 600;
      }
      .text2 {
        margin: 10px 0 30px;
        font-weight: 600;
      }
      .text3 {
        margin: 20px 0;
        color: #94a5c5;
      }
    }
    .right {
      width: 640px;
      height: 100%;
      padding-top: 90px;
      .li {
        width: 50%;
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        .p1 {
          font-size: 50px;
          font-weight: 600;
        }
      }
    }
  }
  .product {
    width: 100%;
    background: #f7f7f7;
    overflow: hidden;
    .content {
      width: 1200px;
      margin: 50px auto;
      overflow: hidden;
      .title {
        font-size: 40px;
        margin-bottom: 30px;
        font-weight: 600;
        padding-left: 20px;
        border-left: 5px solid #004bc3;
        margin-left: 12px;
      }
      .li {
        width: 31%;
        padding: 20px;
        box-sizing: border-box;
        float: left;
        text-align: center;
        background: #fff;
        margin: 1% 1%;
        border: 1px solid #ebeef5;
        border-radius: 10px;
        transition: all 0.5s;
        cursor: pointer;
        height: 446px;
        img {
          width: 100%;
        }
        .text1 {
          height:330px;
          border:1px solid #ebeef5;
          border-radius: 5px;
          box-sizing: border-box;
          overflow: hidden;
        }
        .text2 {
          margin: 10px 0;
          font-weight: 600;
        }
        // .text2:hover {
        //   color: #004bc3;
        // }
        .text3 {
          margin: 10px 0;
          font-size: 14px;
        }
      }
      .li:hover {
        box-shadow: 0 0 15px 3px #ddd;
        transform: translateY(-10px);
        border: 1px solid #ddd;
      }
    }
  }
  .information {
    width: 100%;
    overflow: hidden;
    background: #fff;
    .content {
      width: 1200px;
      height: 100%;
      margin: 50px auto 150px;
      text-align: center;

      .text2 {
        font-size: 40px;
        margin: 30px 0 30px;
        font-weight: 600;
      }
      .text3 {
        color: #94a5c5;
      }
      .list {
        display: flex;

        .li {
          width: 33%;
          padding: 0 100px;
          display: flex;
          flex-direction: column;
          margin-top: 50px;
          text-align: left;
          .li1 {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 20px;
            span {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
  /* 多状态切换 */
  @keyframes banner1 {
    0% {
      opacity: 0;
      top: 80px;
    }
    100% {
      opacity: 1;
      top: 120px;
    }
  }
  @keyframes banner2 {
    0% {
      opacity: 0;
      top: 200px;
    }
    100% {
      opacity: 1;
      top: 240px;
    }
  }
  @keyframes banner3 {
    0% {
      opacity: 0;
      top: 340px;
    }
    100% {
      opacity: 1;
      top: 300px;
    }
  }
}
@media (max-width: 750px) {
  .homePage {
    width: 100%;
    .banner {
      width: 100%;
      height: 150px;
      background-size: cover;
      .content {
        width: 100%;
        margin: 0;
        .text1 {
          font-size: 14px;
          padding: 10% 5% 0 30%;
          top: 0;
          animation: none;
        }
        .text2,
        .text3 {
          display: none;
        }
      }
    }
    .product {
      .content {
        width: 100%;
        margin: 0px;
        .title {
          font-size: 16px;
          margin: 20px;
        }
        .li {
          width: 90%;
          margin: 0% 5% 5% 5%;
          overflow: hidden;
          height: 446px;
          .text1 {
            width: 100%;
          }
        }
        .li:hover {
          box-shadow: none;
          transform: translateY(0px);
        }
      }
    }
  }
}
</style>