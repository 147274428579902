<template>
  <div class="header">
    <div class="content">
      <div class="logo">
        <img src="../assets/logo1.png" alt="" />
      </div>
      <div class="posImg" @click="imgClick">三</div>
      <div class="list" :class="{ phoneHeight: isPhone }">
        <div
          class="li"
          :class="{ select: index == i, phoneHeight: isPhone }"
          v-for="(item, i) in data"
          :key="item"
          @click="menuClick(i)"
        >
          {{ item }}
        </div>
      </div>

      <el-link class="btn" type="primary" @click="$router.push('/admin')"
        >Backstage</el-link
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: ["Home page", "Product page", "product Introduction"],
      index: 0,
      isPhone: false,
    };
  },
  created() {
    this.getindex();
  },
  watch: {
    $route() {
      this.getindex();
    },
  },
  methods: {
    imgClick() {
      this.isPhone = !this.isPhone;
    },
    menuClick(i) {
      this.index = i;
      if (i == 0) {
        this.$router.push("/");
      } else if (i == 1) {
        this.$router.push("/productList");
      } else if (i == 2) {
        this.$router.push("/productIntroduction");
      } else if (i == 3) {
        this.$router.push("/aboutUs");
      }
      this.isPhone = false;
    },
    getindex() {
      let name = this.$route.name;
      if (name == "homePage") {
        this.index = 0;
      } else if (name == "productList") {
        this.index = 1;
      } else if (name == "productIntroduction") {
        this.index = 2;
      } else if (name == "aboutUs") {
        this.index = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 80px;
  background: #fff;
  .content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    align-items: center;
    .btn {
      position: absolute;
      right: 0;
    }
    .posImg {
      display: none;
    }
    .logo {
      width: 150px;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .list {
      margin-left: 50px;
      display: flex;
      align-items: center;
      .li {
        margin: 0 20px;
        padding: 0 10px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        border-bottom: 3px solid #fff;
      }
      .select {
        color: #004bc3;
        border-bottom: 3px solid #004bc3;
      }
    }
  }
}
@media (max-width: 750px) {
  .header {
    width: 100%;
    height: 60px;
    position: relative;
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    .content {
      width: 100%;
      .btn {
        display: none;
      }
      .posImg {
        display: block;
        width: 30px;
        height: 30px;
        border: 1px solid #999;
        border-radius: 5px;
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        line-height: 30px;
        box-shadow: 0 0 5px 2px #ebeef5;
      }
      .posImg:active {
        box-shadow: none;
      }
      .list {
        width: 100%;
        height: 0;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        margin-left: 0;
        transition: all 0.5s;
        overflow: hidden;
        z-index: 2;
        .li {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-bottom: none;
          border-bottom: 1px solid #ddd;
          box-sizing: border-box;
        }
        .select {
          background: #e1edff;
        }
      }
      .phoneHeight {
        height: 120px;
        background: #fff;
        transition: all 0.5s;
      }
    }
  }
}
</style>