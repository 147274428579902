<template>
  <div class="login">
    <div class="con">
      <img class="img" src="../../assets/logo1.png" alt="" />
      <div><b>Background management</b></div>
      <el-input
        placeholder="Please enter your account number"
        size="normal"
        v-model.trim="email"
        clearable
        class="user"
      >
        <template slot="prefix">
          <div class="inputIcon">
            <i class="el-icon-user-solid"></i>
          </div>
        </template>
      </el-input>
      <el-input
        placeholder="Please enter password"
        v-model.trim="password"
        type="password"
        size="normal"
        @change="passwordError = false"
        clearable
        class="pass"
      >
        <template slot="prefix">
          <div class="inputIcon">
            <i class="el-icon-lock"></i>
          </div>
        </template>
      </el-input>

      <el-button
        class="btn"
        style="width: 100%"
        type="primary"
        @click="login"
        size="normal"
        :loading="loading"
        >Log in</el-button
      >
      <el-link type="primary" @click="$router.push('/')">Official website</el-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  mounted() {},
  methods: {
    login() {
      if (!this.password) {
        this.$message.warning("The password cannot be empty");
        return;
      }

      this.loading = true;
      let data = {
        email: this.email,
        password: this.password,
      };
      this.$axios
        .post("/crm/login/login", data)
        .then(async (data) => {
          if (data.code == 1) {
            localStorage.token = data.data.token;
            // 用户信息
            localStorage.setItem(
              "userInfo",
              JSON.stringify(data.data.userInfo)
            );
            this.loading = false;
            this.$router.push({
              path: "/admin",
            });
          } else {
            this.loading = false;
            this.$message.error(data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .con {
    width: 400px;
    border: 1px solid #ddd;
    padding: 30px 60px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 2px #ddd;
    .img {
      width: 200px;
      margin: 20px 0 40px;
    }
    .user {
      margin-top: 20px;
    }
    .pass {
      margin-top: 20px;
    }
    .btn {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
}
</style>
<style lang="scss" >
.login {
  .inputIcon {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 18px;
    color: #004bc3;
    padding-left: 4px;
  }
}
</style>