<template>
  <div class="admin" v-if="view">
    <div class="top">
      <div class="con">
        <div class="ul">
          <div
            class="li"
            :class="{ liIndex: index == i }"
            v-for="(item, i) in title"
            :key="item.id"
            @click="titleClick(i)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="right">
          <el-link type="primary" @click="$router.push('/')">返回官网</el-link>
          <el-link type="primary" style="margin-left: 10px" @click="exitClick"
            >退出登录</el-link
          >
        </div>
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: [
        {
          name: "企业信息",
          id: 1,
        },
        {
          name: "产品管理",
          id: 2,
        },
        {
          name: "文章管理",
          id: 3,
        },
        {
          name: "员工管理",
          id: 4,
        },
      ],
      index: 0,
      view: false,
    };
  },
  created() {
    if (localStorage.token && localStorage.userInfo) {
      this.view = true;
    } else {
      this.view = false;
      this.$router.push({
        path: "/login",
      });
    }
  },
  mounted() {
    if (this.$route.name == "corporateInformation") {
      this.index = 0;
    } else if (this.$route.name == "articleManagement") {
      this.index = 1;
    } else if (this.$route.name == "productManagement") {
      this.index = 2;
    } else if (this.$route.name == "staffManagement") {
      this.index = 3;
    }
  },
  methods: {
    exitClick() {
      this.$confirm("确认退出吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post("/crm/login/logout")
          .then((data) => {
            if (data.code == 1) {
              this.$message({
                type: "success",
                message: "退出成功!",
              });
              localStorage.removeItem("token");
              localStorage.removeItem("userInfo");
              localStorage.removeItem("permissionArr");
              this.$router.push({
                path: "/login",
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    titleClick(i) {
      this.index = i;
      if (i == 0) {
        this.$router.push({ name: "corporateInformation" });
      } else if (i == 1) {
        this.$router.push({
          name: "articleManagement",
        });
      } else if (i == 2) {
        this.$router.push({
          name: "productManagement",
        });
      } else if (i == 3) {
        this.$router.push({
          name: "staffManagement",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.admin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ddd;
    .con {
      width: 1200px;
      margin: 0 auto;
      height: 60px;
      display: flex;
      align-items: center;
      .ul {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        .li {
          padding-right: 40px;
          cursor: pointer;
          font-weight: 600;
        }
        .liIndex {
          color: #004bc3;
        }
      }
      .right {
        flex: none;
      }
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    padding: 30px 0;
  }
}
</style>
