/**
 * 封装的一些全局公用
 */
import { axios } from "@/api/request.js";
import { Message } from "element-ui";
// 上传
// format 上传文件格式 png jpg xls
// type 存放类型，1：系统，2：员工，3：订单，4：仓库，5：客户
// file 上传的文件流
const getUploadSign = (type, file) => {
    return new Promise((resolve, reject) => {
        let value = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (value) {
            axios
                .post("/crm/index/getUploadSign", {
                    postfix: value,
                    type: type,
                })
                .then((res) => {
                    if (res.code == 1) {
                        var xhr = new XMLHttpRequest();
                        xhr.open("PUT", res.data.sign_url);
                        xhr.setRequestHeader("Content-Type", "application/octet-stream");
                        xhr.onload = function () {
                            resolve(res.data.show_url);
                            // 请求结束后，在此处编写处理代码。
                        };
                        xhr.send(file);
                    } else {
                        Message.error("上传失败");
                    }
                }).catch(err => {
                    reject(err);
                });
        } else {
            Message.error("文件格式错误");
        }
    });
};
export default {
    getUploadSign,
};
