<template>
  <div class="productList">
    <div class="top">
      <div class="content">
        <div class="con">
          <div class="text1">
            Multi-function scanbody sets healing, oral scan, <br />take model
            and other functions into an organic whole
          </div>
          <div class="text2">
            <el-input
              v-model="value"
              placeholder="Enter the product you want to search for"
              style="width: 81%"
              size="medium"
              clearable
              @input="inputValue"
            ></el-input>
            <el-button type="primary" @click="searchClick"
              >&nbsp;&nbsp;&nbsp;&nbsp; Search
              &nbsp;&nbsp;&nbsp;&nbsp;</el-button
            >
          </div>
          <div class="text3">
            <span
              >scanning device is suitable for different brands, improve the
              efficiency of clinical operation</span
            >
          </div>
        </div>
      </div>
    </div>
    <template v-if="type">
      <div class="type1" v-if="row && row.list">
        <div class="content">
          <!-- <div
            class="li"
            v-for="(item, i) in row.list"
            :key="i"
            @click="clickIndex1(i, 1)"
            :class="{ selectLi1: index1 == i }"
          >
            {{ item.name }}
          </div> -->

          <el-tabs @tab-click="clickIndex1" v-model="activeName">
            <el-tab-pane
              :label="item.name"
              :name="String(i)"
              v-for="(item, i) in row.list"
              :key="i"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div class="type2" v-if="row && row.list">
        <div class="content">
          <div
            class="li"
            v-for="(item, i) in row.list[Number(activeName)].children"
            :key="i"
            :class="{ selectLi2: index2 == i }"
            @click="clickIndex2(i)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </template>
    <div class="list" v-loading="loading" element-loading-text="Loading...">
      <!-- <div class="title">Product List</div> -->
      <template v-if="dataList.length">
        <div
          v-for="(item, i) in dataList"
          :key="i"
          class="li"
          @click="liClick(item.id)"
        >
          <div class="text1"><img :src="item.cover" alt="" /></div>
          <div class="text2">
            <span @click="liClick(item.id)">{{ item.category }}</span>
          </div>
          <div class="text3">{{ item.name }}</div>
        </div>
      </template>
      <div v-else>No data available</div>
    </div>
    <ProductDetails
      v-if="liShow"
      :id="id"
      @close="liShow = false"
    ></ProductDetails>
  </div>
</template>

<script>
import ProductDetails from "../components/productDetails.vue";
export default {
  components: {
    ProductDetails,
  },
  data() {
    return {
      value: "",
      row: [],
      liShow: false,
      id: "",
      index2: 0,
      dataList: [],
      loading: false,
      type: true,
      activeName: "",
    };
  },
  async created() {
    await this.getinit();
    this.getList(this.row.list[0].children[0].id);
  },
  methods: {
    inputValue(val) {
      if (!val) {
        this.type = true;
        this.activeName = 0;
        this.index2 = 0;
        this.getList(this.row.list[0].children[0].id);
      }
    },
    searchClick() {
      this.type = false;
      this.$axios
        .post("/index/product/list", {
          keyword: this.value,
          page_size: 100,
        })
        .then((res) => {
          if (res.code == 1) {
            this.dataList = res.data.list;
          }
          this.loading = false;
        });
    },
    clickIndex1() {
      this.index2 = 0;
      this.getList(this.row.list[this.activeName].children[0].id);
    },
    clickIndex2(i) {
      this.index2 = i;
      this.getList(this.row.list[this.activeName].children[this.index2].id);
    },
    getList(id) {
      this.loading = true;
      this.$axios
        .post("/index/product/list", {
          category_id: id,
          page_size: 100,
        })
        .then((res) => {
          if (res.code == 1) {
            this.dataList = res.data.list;
          }
          this.loading = false;
        });
    },
    getinit() {
      return this.$axios
        .post("/index/product/index", {
          page_size: 100,
        })
        .then((res) => {
          if (res.code == 1) {
            this.row = res.data;
          }
        });
    },
    liClick(id) {
      this.id = id;
      this.liShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.productList {
  width: 100%;
  overflow: hidden;
  background: #f8f8f8;
  .top {
    width: 100%;
    height: 500px;
    background: url("../assets/banner1.png") no-repeat 100% 50%;
    background-size: 100% 100%;
    .content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;

      .con {
        width: 1000px;
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        .text1 {
          font-size: 30px;
          text-align: center;
          color: #333;
          font-weight: 600;
          position: absolute;
          top: 120px;
          left: 0;
          right: 0;
          margin: auto;
          animation: banner1 2s ease;
        }
        .text2 {
          width: 700px;
          position: absolute;
          top: 220px;
          left: 0;
          right: 0;
          margin: auto;
          animation: banner2 2.5s ease;
        }
        .text3 {
          position: absolute;
          top: 280px;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          animation: banner3 2s ease;
        }
      }
    }
    @keyframes banner1 {
      0% {
        opacity: 0;
        top: 80px;
      }
      100% {
        opacity: 1;
        top: 120px;
      }
    }
    @keyframes banner2 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes banner3 {
      0% {
        opacity: 0;
        top: 340px;
      }
      100% {
        opacity: 1;
        top: 280px;
      }
    }
  }
  .type1 {
    width: 100%;
    height: 60px;
    background: #f8f8f8;
    border-bottom: 1px solid #ebeef5;
    .content {
      width: 1200px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      .li {
        margin-right: 30px;

        padding-bottom: 8px;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        border-bottom: 3px solid #f8f8f8;
        margin-top: 10px;
      }
      .selectLi1 {
        color: #004bc3;
        border-bottom: 3px solid #004bc3;
      }
    }
  }
  .type2 {
    width: 100%;
    height: 50px;
    background: #fff;
    border-bottom: 1px solid #ebeef5;
    .content {
      width: 1200px;
      height: 100%;
      margin: auto;
      margin: auto;
      display: flex;
      align-items: center;
      .li {
        float: left;
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      }
      .li:hover {
        color: #004bc3;
      }
      .selectLi2 {
        color: #004bc3;
      }
    }
  }

  .list {
    width: 1200px;
    margin: 30px auto 50px;
    min-height: 200px;
    display: grid;
    justify-content: space-between;
    // 划分列 功能函数关键字 repeat （auto-fill，45px）
    // 根据子元素的盒子的份额自动计算可以平铺几次
    grid-template-columns: repeat(auto-fill, 220px);
    // grid-gap 是 row-gap 和 column-gap 的简写形式。
    // grid-gap: 0 10px;
    .title {
      font-size: 40px;
      margin-bottom: 30px;
      font-weight: 600;
      text-align: center;
    }
    .li {
      background: #fff;
      width: 220px;
      box-sizing: border-box;
      text-align: center;
      border: 1px solid #ebeef5;
      border-radius: 5px;
      transition: all 0.5s;
      height: 342px;
      padding: 15px;
      margin-bottom: 15px;
      float: left;
      cursor: pointer;
      .text1 {
        width: 188px;
        border: 1px solid #ebeef5;
        border-radius: 5px;
        box-sizing: border-box;
        height: 188px;
        overflow: hidden;
        img{
          width:100%;

        }
      }
      .text2 {
        margin: 20px 0 10px;
        font-weight: 600;
      }
      .text3 {
        margin: 10px 0 10px;
        font-size: 13px;
      }
      // .text2:hover {
      //   color: #004bc3;
      // }
    }
    .li:hover {
      box-shadow: 0 0 10px 3px #ddd;
      transform: translateY(-10px);
      border: 1px solid #ddd;
    }
  }
}
@media (max-width: 750px) {
  .productList {
    .top {
      height: 150px;
      .content {
        width: 100%;
        .con {
          width: 100%;
          margin: 0;
          .text1 {
            display: none;
            animation: none;
          }
          .text2 {
            display: none;
            animation: none;
          }
          .text3 {
            padding: 0 20px;
            margin-top: 40px;
            font-size: 16px;
            font-weight: 600;
            animation: none;
            top: 0;
          }
        }
      }
    }
    .type1 {
      width: 100%;
      font-size: 15px;
      height: auto;
      .content {
        width: auto;
        padding: 0 20px;
        display: block;
      }
    }
    .type1 ::v-deep .el-tabs__header {
      margin-bottom: 0;
    }

    .type1 ::v-deep .el-icon-arrow-right:before {
      font-weight: 600;
      font-size: 20px;
    }
    .type1 ::v-deep .el-icon-arrow-left:before {
      font-weight: 600;
      font-size: 20px;
    }
    .type1 ::v-deep .el-tabs__nav-next {
      text-align: center;
      width: 30px;
    }
    .type1 ::v-deep .el-tabs__nav-prev {
      text-align: center;
      width: 30px;
    }
    .type1 ::v-deep .el-tabs__nav-wrap.is-scrollable {
      padding: 0 30px;
    }

    .type2 {
      width: 100%;
      height: auto;
      .content {
        width: 100%;
        display: block;
        .li {
          width: 100%;
          float: none;
          padding: 8px 0 8px 20px;
        }
      }
    }
    .list {
      width: auto;
      margin: 20px;
      display: block;
      .li {
        width: 100%;
        float: none;
        height: 446px;
      }
      .li:hover {
        box-shadow: none;
        transform: translateY(0px);
      }
    }
  }
}
</style>
<style lang="scss">
.productList {
  .el-input--medium .el-input__inner {
    height: 40px !important;
    line-height: 40px !important;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__item {
    font-weight: 600;
  }
  .el-tabs__active-bar {
    height: 2.5px;
  }
}
</style>