<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1200px;
  overflow: auto;
  font-family: "微软雅黑" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  height: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f8f8f8;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #e0e0e0;
}
.el-dialog__header {
  border-bottom: 1px solid #ebeef5;
}
.el-dialog__footer {
  border-top: 1px solid #ebeef5;
}

.el-dialog__body {
  max-height: 550px !important;
  overflow: auto;
}
.el-table--border,
.el-table--group {
  border-top: 1px solid #ddd !important;
  border-left: 1px solid #ddd !important;
}
.el-table--border td,
.el-table--border th {
  border-right: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #ddd !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background: #ddd !important;
}
.el-table--small th,
.el-table--small td {
  padding: 5px 0 !important;
}
.el-table th {
  background: #eff2f5 !important;
  color: #333;
}
.el-table__header-wrapper {
  line-height: 20px !important;
}
@media (max-width: 750px) {
  #app {
    width: 100%;
    min-width: auto;
  }
  .el-dialog__body {
    max-height: none !important;
    overflow: auto;
  }
}
</style>
